import {
  NO_DECIMAL_NUMERAL_TEXT,
  NO_NEGATIVE_NUMERAL_TEXT,
  NO_ZERO_NUMERAL_TEXT,
  NUMBER,
  NUMERAL,
} from '../constants';
import {
  addTooltip,
  clearStack,
  getTokenizedStack,
  numberToNumeral,
  numeralToNumber,
  updateCurExpr,
  updatePrevExpr,
} from '../utils';

export default function handleConvert(expressionStack, event) {
  const tokenizedStack = getTokenizedStack(expressionStack);
  const lastToken = tokenizedStack[tokenizedStack.length - 1];

  if (lastToken.type === NUMBER) {
    const number = +lastToken.value;

    if (number === 0) {
      addTooltip(event, event.target, NO_ZERO_NUMERAL_TEXT);
    } else if (number < 0) {
      addTooltip(event, event.target, NO_NEGATIVE_NUMERAL_TEXT);
    } else if (!Number.isInteger(number)) {
      addTooltip(event, event.target, NO_DECIMAL_NUMERAL_TEXT);
    } else {
      const converted = numberToNumeral(number);

      if (tokenizedStack.length === 1) {
        clearStack(expressionStack);
        updatePrevExpr(`${number} &rArr; `);
      } else {
        lastToken.value.split('').forEach(() => expressionStack.pop());
      }

      converted.split('').forEach(e => expressionStack.push({ type: NUMERAL, value: e }));
    }
  }

  if (lastToken.type === NUMERAL) {
    const numeral = lastToken.value;
    const converted = numeralToNumber(numeral);

    if (tokenizedStack.length === 1) {
      clearStack(expressionStack);
      updatePrevExpr(`${numeral} &rArr; `);
    } else {
      lastToken.value.split('').forEach(() => expressionStack.pop());
    }

    `${converted}`.split('').forEach(e => expressionStack.push({ type: NUMBER, value: e }));
  }

  updateCurExpr(expressionStack);
}
