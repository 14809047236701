import { NUMBER } from '../constants';
import { clearStack, updateCurExpr, updatePrevExpr } from '../utils';

export default function handleClear(expressionStack) {
  clearStack(expressionStack);
  expressionStack.push({ type: NUMBER, value: '0' });

  updateCurExpr(expressionStack);
  updatePrevExpr();
}
