import { getStringFromStack } from './expressionStack';

const prevExpr = document.querySelector('.calculator-prev-expr');
const curExpr = document.querySelector('.calculator-cur-expr');

export function updatePrevExpr(content = '') {
  prevExpr.innerHTML = content;
}

export function updateCurExpr(expressionStack) {
  curExpr.innerHTML = getStringFromStack(expressionStack);
}
