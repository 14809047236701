import { CLOSE_PAREN, NUMBER, NUMERAL } from '../constants';
import { getLastToken, updateCurExpr } from '../utils';

export default function handleNumber(expressionStack, value) {
  const lastEntry = expressionStack[expressionStack.length - 1];
  const lastToken = getLastToken(expressionStack);
  const newEntry = { type: NUMBER, value };

  if (lastToken.value === '0') {
    expressionStack[expressionStack.length - 1] = newEntry;
  } else if (lastEntry.type !== NUMERAL && lastEntry.type !== CLOSE_PAREN) {
    expressionStack.push(newEntry);
  }

  updateCurExpr(expressionStack);
}
