import { DECIMAL, NEGATE, OPEN_PAREN, OPERATOR } from '../constants';
import { updateCurExpr } from '../utils';

export default function handleOperator(expressionStack, value) {
  const lastEntry = expressionStack[expressionStack.length - 1];
  const newEntry = { type: OPERATOR, value };

  if (lastEntry.type === OPERATOR || lastEntry.type === DECIMAL) {
    expressionStack[expressionStack.length - 1] = newEntry;
  } else if (lastEntry.type !== OPEN_PAREN && lastEntry.type !== NEGATE) {
    expressionStack.push(newEntry);
  }

  updateCurExpr(expressionStack);
}
