import { NUMBER } from './constants';
import {
  handleBackspace,
  handleClear,
  handleConvert,
  handleDecimal,
  handleEquals,
  handleNegate,
  handleNumber,
  handleNumeral,
  handleOperator,
  handleParens,
} from './handlers';
import { updateCurExpr } from './utils';

const expressionStack = [{ type: NUMBER, value: '0' }];

const selectorListenerMap = {
  '.calculator-btn-clear': event => handleClear(expressionStack, event),
  '.calculator-btn-backspace': event => handleBackspace(expressionStack, event),
  '.calculator-btn-decimal': event => handleDecimal(expressionStack, event),
  '.calculator-btn-negate': event => handleNegate(expressionStack, event),
  '.calculator-btn-parens': event => handleParens(expressionStack, event),
  '.calculator-btn-divide': event => handleOperator(expressionStack, '/', event),
  '.calculator-btn-7': event => handleNumber(expressionStack, '7', event),
  '.calculator-btn-8': event => handleNumber(expressionStack, '8', event),
  '.calculator-btn-9': event => handleNumber(expressionStack, '9', event),
  '.calculator-btn-d': event => handleNumeral(expressionStack, 'D', event),
  '.calculator-btn-m': event => handleNumeral(expressionStack, 'M', event),
  '.calculator-btn-times': event => handleOperator(expressionStack, '*', event),
  '.calculator-btn-4': event => handleNumber(expressionStack, '4', event),
  '.calculator-btn-5': event => handleNumber(expressionStack, '5', event),
  '.calculator-btn-6': event => handleNumber(expressionStack, '6', event),
  '.calculator-btn-l': event => handleNumeral(expressionStack, 'L', event),
  '.calculator-btn-c': event => handleNumeral(expressionStack, 'C', event),
  '.calculator-btn-minus': event => handleOperator(expressionStack, '-', event),
  '.calculator-btn-1': event => handleNumber(expressionStack, '1', event),
  '.calculator-btn-2': event => handleNumber(expressionStack, '2', event),
  '.calculator-btn-3': event => handleNumber(expressionStack, '3', event),
  '.calculator-btn-v': event => handleNumeral(expressionStack, 'V', event),
  '.calculator-btn-x': event => handleNumeral(expressionStack, 'X', event),
  '.calculator-btn-plus': event => handleOperator(expressionStack, '+', event),
  '.calculator-btn-0': event => handleNumber(expressionStack, '0', event),
  '.calculator-btn-convert': event => handleConvert(expressionStack, event),
  '.calculator-btn-i': event => handleNumeral(expressionStack, 'I', event),
  '.calculator-btn-equals': event => handleEquals(expressionStack, event),
};

Object.entries(selectorListenerMap).forEach(([selector, listener]) =>
  document.querySelector(selector).addEventListener('click', listener)
);

updateCurExpr(expressionStack);
