import { CLOSE_PAREN, DECIMAL, NEGATE, NUMBER, NUMERAL, OPEN_PAREN, OPERATOR } from '../constants';
import { getLastToken, updateCurExpr } from '../utils';

export default function handleParens(expressionStack) {
  const lastEntry = expressionStack[expressionStack.length - 1];
  const lastToken = getLastToken(expressionStack);
  const openParensCount =
    expressionStack.filter(e => e.type === OPEN_PAREN).length -
    expressionStack.filter(e => e.type === CLOSE_PAREN).length;

  if (
    openParensCount > 0 &&
    (lastEntry.type === NUMBER ||
      lastEntry.type === NUMERAL ||
      lastEntry.type === DECIMAL ||
      lastEntry.type === CLOSE_PAREN)
  ) {
    if (lastEntry.type === DECIMAL) {
      expressionStack.pop();
    }

    expressionStack.push({ type: CLOSE_PAREN, value: ')' });
  } else if (
    lastEntry.type === OPERATOR ||
    lastEntry.type === NEGATE ||
    lastEntry.type === OPEN_PAREN
  ) {
    expressionStack.push({ type: OPEN_PAREN, value: '(' });
  } else if (lastToken.value === '0') {
    expressionStack.pop();
    expressionStack.push({ type: OPEN_PAREN, value: '(' });
  }

  updateCurExpr(expressionStack);
}
