import handleBackspace from './handleBackspace';
import handleClear from './handleClear';
import handleConvert from './handleConvert';
import handleDecimal from './handleDecimal';
import handleEquals from './handleEquals';
import handleNegate from './handleNegate';
import handleNumber from './handleNumber';
import handleNumeral from './handleNumeral';
import handleOperator from './handleOperator';
import handleParens from './handleParens';

export {
  handleBackspace,
  handleClear,
  handleConvert,
  handleDecimal,
  handleEquals,
  handleNegate,
  handleNumber,
  handleNumeral,
  handleOperator,
  handleParens,
};
