export const NUMBER = 'number';
export const NUMERAL = 'numeral';
export const OPERATOR = 'operator';
export const NEGATE = 'negate';
export const DECIMAL = 'decimal';
export const OPEN_PAREN = 'open-paren';
export const CLOSE_PAREN = 'close-paren';

export const NO_ZERO_NUMERAL_TEXT = 'There is no Roman numeral for the number 0.';
export const NO_NEGATIVE_NUMERAL_TEXT = 'Roman numerals cannot be negative.';
export const NO_DECIMAL_NUMERAL_TEXT = 'Roman numerals cannot contain decimals.';
export const IS_INVALID_NUMERAL_TEXT = 'is not a valid Roman numeral.';
export const UNEXPECTED_ERROR_TEXT = 'An unexpected error occurred. Please try again.';
