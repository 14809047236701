const validNumeralPattern = /^(M*)(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;

export function isValidNumeral(numeral) {
  return validNumeralPattern.test(numeral);
}

const numeralNumberMap = new Map([
  ['I', 1],
  ['V', 5],
  ['X', 10],
  ['L', 50],
  ['C', 100],
  ['D', 500],
  ['M', 1000],
]);

export function numeralToNumber(numeral) {
  let result = 0;
  let prevValue = 0;

  for (let i = numeral.length - 1; i >= 0; i--) {
    const curValue = numeralNumberMap.get(numeral[i]);

    if (curValue < prevValue) {
      result -= curValue;
    } else {
      result += curValue;
    }

    prevValue = curValue;
  }

  return result;
}

const numberNumeralPairs = [
  { value: 1000, numeral: 'M' },
  { value: 900, numeral: 'CM' },
  { value: 500, numeral: 'D' },
  { value: 400, numeral: 'CD' },
  { value: 100, numeral: 'C' },
  { value: 90, numeral: 'XC' },
  { value: 50, numeral: 'L' },
  { value: 40, numeral: 'XL' },
  { value: 10, numeral: 'X' },
  { value: 9, numeral: 'IX' },
  { value: 5, numeral: 'V' },
  { value: 4, numeral: 'IV' },
  { value: 1, numeral: 'I' },
];

export function numberToNumeral(number) {
  return numberNumeralPairs.reduce((acc, pair) => {
    const factor = Math.floor(number / pair.value);

    number -= pair.value * factor;

    return acc + pair.numeral.repeat(factor);
  }, '');
}
