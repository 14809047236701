import {
  CLOSE_PAREN,
  NEGATE,
  NO_NEGATIVE_NUMERAL_TEXT,
  NUMBER,
  NUMERAL,
  OPEN_PAREN,
  OPERATOR,
} from '../constants';
import { addTooltip, getLastToken, updateCurExpr } from '../utils';

export default function handleNegate(expressionStack, event) {
  const lastToken = getLastToken(expressionStack);

  if (lastToken.type === NUMERAL) {
    addTooltip(event, event.target, NO_NEGATIVE_NUMERAL_TEXT);
  } else if (lastToken.type === OPERATOR || lastToken.type === OPEN_PAREN) {
    expressionStack.push({ type: NEGATE, value: '-' });
  } else if (lastToken.type === CLOSE_PAREN) {
    const poppedStack = [];

    [...expressionStack].reverse().forEach(e => {
      const poppedEntry = expressionStack.pop();

      if (poppedEntry) {
        poppedStack.unshift(poppedEntry);
      }

      if (e.type === OPEN_PAREN) {
        if (expressionStack[expressionStack.length - 1]?.type === NEGATE) {
          expressionStack.pop();
        } else {
          expressionStack.push({ type: NEGATE, value: '-' });
        }
      }
    });

    poppedStack.forEach(e => expressionStack.push(e));
  } else if (lastToken.type === NEGATE) {
    expressionStack.pop();
  } else if (!(lastToken.type === NUMBER && +lastToken.value === 0)) {
    lastToken.value.split('').forEach(() => expressionStack.pop());

    if (+lastToken.value > 0) {
      expressionStack.push({ type: NEGATE, value: '-' });
      lastToken.value.split('').forEach(e => expressionStack.push({ type: NUMBER, value: e }));
    } else {
      lastToken.value
        .slice(1)
        .split('')
        .forEach(e => expressionStack.push({ type: NUMBER, value: e }));
    }
  }

  updateCurExpr(expressionStack);
}
