import {
  CLOSE_PAREN,
  DECIMAL,
  IS_INVALID_NUMERAL_TEXT,
  NEGATE,
  NO_NEGATIVE_NUMERAL_TEXT,
  NUMBER,
  NUMERAL,
} from '../constants';
import { addTooltip, getLastToken, isValidNumeral, updateCurExpr } from '../utils';

export default function handleNumeral(expressionStack, value, event) {
  const lastEntry = expressionStack[expressionStack.length - 1];
  const lastToken = getLastToken(expressionStack);
  const proposedNumeral =
    lastEntry.type === NUMERAL ? `${getLastToken(expressionStack).value}${value}` : value;
  const newEntry = { type: NUMERAL, value };

  if (!isValidNumeral(proposedNumeral)) {
    addTooltip(event, event.target, `${proposedNumeral} ${IS_INVALID_NUMERAL_TEXT}`);
  } else if (lastEntry.type === NEGATE) {
    addTooltip(event, event.target, NO_NEGATIVE_NUMERAL_TEXT);
  } else if (lastToken.value === '0') {
    expressionStack[expressionStack.length - 1] = newEntry;
  } else if (
    lastEntry.type !== NUMBER &&
    lastEntry.type !== DECIMAL &&
    lastEntry.type !== CLOSE_PAREN
  ) {
    expressionStack.push(newEntry);
  }

  updateCurExpr(expressionStack);
}
