import { DECIMAL, NEGATE, NUMBER, NUMERAL, OPERATOR } from '../constants';
import { numeralToNumber } from './numerals';

export function clearStack(stack) {
  stack.splice(0, stack.length);
}

export function getStringFromStack(stack) {
  const operatorMap = {
    '+': '&plus;',
    '-': '&minus;',
    '*': '&times;',
    '/': '&divide;',
  };

  return stack.reduce((acc, entry) => {
    if (entry.type === NEGATE) {
      return `${acc}${operatorMap[entry.value]}`;
    }

    if (entry.type === OPERATOR) {
      return `${acc} ${operatorMap[entry.value]} `;
    }

    return `${acc}${entry.value}`;
  }, '');
}

export function getEvaluatableStringFromStack(stack) {
  return getTokenizedStack(stack).reduce((acc, token) => {
    if (token.type === NUMERAL) {
      return `${acc}bignumber(${numeralToNumber(token.value)})`;
    }

    if (token.type === NUMBER) {
      return `${acc}bignumber(${token.value})`;
    }

    return `${acc}${token.value}`;
  }, '');
}

export function getTokenizedStack(stack) {
  return stack.reduce((acc, cur) => {
    const prev = acc[acc.length - 1] || {};

    if (
      (prev.type === NEGATE && cur.type === NUMBER) ||
      (prev.type === NUMBER && cur.type === DECIMAL)
    ) {
      return [...acc.slice(0, -1), { type: NUMBER, value: `${prev.value}${cur.value}` }];
    }

    if (prev.type === cur.type) {
      return [...acc.slice(0, -1), { type: prev.type, value: `${prev.value}${cur.value}` }];
    }

    return [...acc, cur];
  }, []);
}

export function getLastToken(stack) {
  const tokenizedStack = getTokenizedStack(stack);

  return tokenizedStack[tokenizedStack.length - 1];
}
