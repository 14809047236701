import { evaluate } from 'mathjs';
import { DECIMAL, NEGATE, NUMBER, UNEXPECTED_ERROR_TEXT } from '../constants';
import {
  addTooltip,
  clearStack,
  getEvaluatableStringFromStack,
  getStringFromStack,
  updateCurExpr,
  updatePrevExpr,
} from '../utils';

export default function handleEquals(expressionStack, event) {
  try {
    const evaluatableString = getEvaluatableStringFromStack(expressionStack);

    const calculationResult = evaluate(evaluatableString);

    const roundedResult =
      Math.round((+`${calculationResult}` + Number.EPSILON) * 10000000000) / 10000000000;

    if (!`${roundedResult}`.split('').every(e => '-.0123456789'.includes(e))) {
      throw new Error();
    }

    updatePrevExpr(`${getStringFromStack(expressionStack)} = `);

    clearStack(expressionStack);

    `${roundedResult}`.split('').forEach(e => {
      if (e === '-') {
        expressionStack.push({ type: NEGATE, value: e });
      } else if (e === '.') {
        expressionStack.push({ type: DECIMAL, value: e });
      } else {
        expressionStack.push({ type: NUMBER, value: e });
      }
    });

    updateCurExpr(expressionStack);
  } catch (err) {
    addTooltip(event, event.target, UNEXPECTED_ERROR_TEXT);
  }
}
