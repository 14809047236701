import {
  CLOSE_PAREN,
  DECIMAL,
  NO_DECIMAL_NUMERAL_TEXT,
  NUMBER,
  NUMERAL,
  OPEN_PAREN,
  OPERATOR,
} from '../constants';
import { addTooltip, getLastToken, updateCurExpr } from '../utils';

export default function handleDecimal(expressionStack, event) {
  const lastToken = getLastToken(expressionStack);
  const lastEntry = expressionStack[expressionStack.length - 1];
  const newEntry = { type: DECIMAL, value: '.' };

  if (lastEntry.type === NUMERAL) {
    addTooltip(event, event.target, NO_DECIMAL_NUMERAL_TEXT);
  } else if (lastEntry.type === OPERATOR || lastEntry.type === OPEN_PAREN) {
    expressionStack.push({ type: NUMBER, value: '0' });
    expressionStack.push(newEntry);
  } else if (
    lastEntry.type !== DECIMAL &&
    lastEntry.type !== CLOSE_PAREN &&
    !(lastToken.type === NUMBER && !Number.isInteger(+lastToken.value))
  ) {
    expressionStack.push(newEntry);
  }

  updateCurExpr(expressionStack);
}
