import { NUMBER } from '../constants';
import { updateCurExpr } from '../utils';

export default function handleBackspace(expressionStack) {
  if (expressionStack.length === 1) {
    expressionStack[0] = { type: NUMBER, value: '0' };
  } else {
    expressionStack.pop();
  }

  updateCurExpr(expressionStack);
}
