export function addTooltip(event, element, message) {
  document.querySelectorAll('.tooltip').forEach(t => t.remove());

  const tooltip = document.createElement('span');
  tooltip.classList = 'tooltip';
  tooltip.role = 'alert';
  tooltip.tabIndex = -1;
  tooltip.innerHTML = message;

  element.append(tooltip);

  tooltip.focus();

  document.addEventListener('click', () => tooltip.remove(), { once: true });

  event.stopPropagation();
}
